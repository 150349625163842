jQuery(document).ready(function() {
  // Add class to body when scrolling to add white background
  var targetDiv = jQuery('body');

  jQuery(window).scroll(function() {

       var windowpos = jQuery(window).scrollTop();

	   // change amount here to choose distance from top to add class
       if( windowpos >= 50 ) {
            targetDiv.addClass('scrolling-active');
       } else {
            targetDiv.removeClass('scrolling-active');
       }

  });
});

document.addEventListener("DOMContentLoaded", function () {
    const toggleButton = document.getElementById("toggleButtonHeader");
    const image1 = sjys_js.theme_url+"/icons/Hamburger.svg";
    const image2 = sjys_js.theme_url+"/icons/Cross.svg";
    let isImage1Visible = true;

    toggleButton.addEventListener("click", function () {
        console.log("clicked")
      if (isImage1Visible) {
        toggleButton.innerHTML = `<img src="${image2}" alt="IYG Logo">`;
        document.body.classList.add('no-scroll');
      } else {
        toggleButton.innerHTML = `<img src="${image1}" alt="IYG Logo">`;
        document.body.classList.remove('no-scroll');
      }
      isImage1Visible = !isImage1Visible;
    });
  });


jQuery(document).ready(function() {
  document.getElementById('header-search-button').onclick=function(e) {

		let desktop_section = document.getElementById('formsearchd');
      
	    if (jQuery('body').hasClass('home') || jQuery('.ysp-yacht-search-form').length > 0) {
        if (jQuery('.ysp-quick-search-form').length > 0) {
          var headerHeight = 110; 
          var targetPosition = jQuery('#ysp-h-yacht-quick-search').offset().top - headerHeight;
          jQuery('html, body').animate({
              scrollTop: targetPosition
          }, 250);
      }
            else if (jQuery('.ysp-yacht-search-form').length > 0) {
              var headerHeight = 110; 
              var targetPositions = jQuery('.ysp-yacht-search-form').offset().top - headerHeight;
              jQuery('html, body').animate({
                  scrollTop: targetPositions
              }, 250);
            }

	    }
	    else {
			if (desktop_section.style.display == 'none') {
				desktop_section.style.display='block';
			}
			else {
				desktop_section.style.display='none';
			}
	    }
    }

    let closeIcon = document.getElementById("closeIcon");
    let searchForm = document.getElementById("formsearchd");

    closeIcon.addEventListener("click", function() {
        searchForm.style.display = "none";
    });
});