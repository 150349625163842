document.addEventListener("DOMContentLoaded", () => {

  var mainYachtDetailsSlider = new Flickity(".carousel-main", {
    wrapAround: true,
    cellAlign: "center",
    contain: true,
    pageDots: false,
  });

  if (document.getElementById("firstGallery")) {
    lightGallery(document.getElementById("firstGallery"), {
      plugins: [lgZoom, lgVideo, lgRotate],
      speed: 500,
      thumbnail: true,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: ".slider-cell",
      lazyLoad: true,
    });
  }

  var secondaryYachtDetailsSlider = new Flickity(".secondary-gallery", {
    wrapAround: true,
    cellAlign: "left",
    container: true,
    pageDots: false,
  });

  if (document.getElementById("secondaryGallery")) {
    lightGallery(document.getElementById("secondaryGallery"), {
      plugins: [lgZoom, lgVideo, lgRotate],
      speed: 500,
      thumbnail: true,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: ".second-cell",
      lazyLoad: 3,
    });
  }
  if (document.getElementById("secondaryGallery")) {
    lightGallery(document.getElementById("secondaryGallery"), {
      plugins: [lgZoom, lgVideo, lgRotate],
      speed: 500,
      thumbnail: true,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: ".carousel-cells",
      lazyLoad: 3,
    });
  }
  if (document.getElementById("firstGallery")) {
    lightGallery(document.getElementById("firstGallery"), {
      plugins: [lgZoom, lgVideo, lgRotate],
      speed: 500,
      thumbnail: true,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: ".carousel-cell",
      lazyLoad: 3,
    });
  }

  if (document.getElementById("video-button")) {
    lightGallery(document.getElementById("video-button"), {
      plugins: [lgZoom, lgVideo, lgRotate],
      speed: 500,
      thumbnail: true,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: ".carousel-cell",
      lazyLoad: 3,
    });
  }
});
