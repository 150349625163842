	ysp_templates.yacht={};
	
	ysp_templates.yacht.grid = function(vessel) {

		let price = '';

		if (typeof vessel.Price == 'string') {
			price = vessel.Price.slice(0, -3);
		}

		if(ysp_yacht_sync.europe_option_picked == "yes"){
			price = vessel.Price ? `€ ${new Intl.NumberFormat('en-us', { minimumFractionDigits: 2}).format((parseInt(vessel.Price.slice(0, -3)) * ysp_yacht_sync.euro_c_c))}` : 'Contact Us For Price';
		} else {
			price = vessel.Price ? `$ ${new Intl.NumberFormat('en-us', { minimumFractionDigits: 2}).format(parseInt(vessel.Price.slice(0, -3)))}` : 'Contact Us For Price'
		}

		let boatLocation = '';
		if (vessel.BoatLocation){
			if (vessel.BoatLocation.BoatCountryID == "US"){
				boatLocation = `${vessel.BoatLocation.BoatCityName}, ${vessel.BoatLocation.BoatStateCode}`;
			} else {
				boatLocation = `${vessel.BoatLocation.BoatStateCode}, ${vessel.BoatLocation.BoatCountryID}`;
			}
		}
		
		return `
			<div class="yacht-results-grid-item" data-post-id="${vessel._postID}" data-yacht-id="${vessel.DocumentID}">
				<div class="yacht-results-card ">
					<div class="yacht-card-image">
						<a href="${ vessel._link }">
							<img loading="lazy" src="${vessel.Images[0].Uri }" alt="featured-image" />
						</a>
					</div>
					<div class="yacht-card-details">
						<a href="${ vessel._link }">
							<h6 class="yacht-card-title">${vessel.ModelYear ? vessel.ModelYear : ''} ${vessel.MakeString ? vessel.MakeString : ''} ${vessel.Model ? vessel.Model : ''} ${vessel.BoatName ? vessel.BoatName : ''}</h6>
						</a>
						<p class="yacht-card-location">${boatLocation}</p>
						<p class="yacht-card-price">${price}</p>
						<div class="yacht-card-flag-button-container">
							<div class="yacht-card-flag-container">
								${ (((typeof vessel.CompanyBoat != 'undefined') && (vessel.CompanyBoat === 1 || vessel.CompanyBoat == '1'))?'<p class="yacht-card-banner">SJYS Listing</p>':"") }

								<!-- <p class="yacht-card-banner">New Listing</p> -->
							</div>
							<a href="${ vessel._link }" class="yacht-card-button-container">
								<img loading="lazy" src="${sjys_js.theme_url}/images/right-arrow.svg" alt="yacht-button-link" />
							</a>
						</div>
					</div>
				</div>
			</div>
		`;
	};

	ysp_templates.yacht.list = function(vessel) {
		let meters = parseInt(vessel.NominalLength) * 0.3048;
		let price = '';

		if (typeof vessel.Price == 'string') {
			let price = vessel.Price.slice(0, -3);
		}
		
		let length = '';
		
		if(ysp_yacht_sync.europe_option_picked == "yes"){
			length = vessel.NominalLength ? meters.toFixed(2) + ' m' : 'N/A';
			price = vessel.Price ? `€ ${new Intl.NumberFormat('en-us', { minimumFractionDigits: 2}).format((parseInt(vessel.Price.slice(0, -3)) * ysp_yacht_sync.euro_c_c))}` : 'Contact Us For Price';
		} else {
			length = vessel.NominalLength ? vessel.NominalLength + " / " + meters.toFixed(2) + ' m' : 'N/A';
			price = vessel.Price ? `$ ${new Intl.NumberFormat('en-us', { minimumFractionDigits: 2}).format(parseInt(vessel.Price.slice(0, -3)))}` : 'Contact Us For Price'
		}

		let boatLocation = '';

		if (vessel.BoatLocation){
			if (vessel.BoatLocation.BoatCountryID == "US"){
				boatLocation = `${vessel.BoatLocation.BoatCityName}, ${vessel.BoatLocation.BoatStateCode}`;
			} else {
				boatLocation = `${vessel.BoatLocation.BoatStateCode}, ${vessel.BoatLocation.BoatCountryID}`;
			}
		}

		return `
			<div class="yacht-results-list-item" data-post-id="${vessel._postID}" data-yacht-id="${vessel.DocumentID}">
				<div class="yacht-results-card list-view">
					<div class="yacht-card-image">
						<a href="${ vessel._link }">
							<img loading="lazy" src="${vessel.Images[0].Uri }" alt="featured-image" />
						</a>
					</div>
					<div class="yacht-card-details">
						<a href="${ vessel._link }">
							<h6 class="yacht-card-title">${vessel.ModelYear ? vessel.ModelYear : ''} ${vessel.MakeString ? vessel.MakeString : ''} ${vessel.Model ? vessel.Model : ''} ${vessel.BoatName ? vessel.BoatName : ''}</h6>
						</a>
						<p class="yacht-card-location">${boatLocation}</p>
						<p class="yacht-card-price">${price}</p>
						<div class="yacht-card-flag-button-container">
							<div class="yacht-card-flag-container">
								${ (((typeof vessel.CompanyBoat != 'undefined') && (vessel.CompanyBoat === 1 || vessel.CompanyBoat == '1'))?'<p class="yacht-card-banner">SJYS Listing</p>':"") }

								<!-- <p class="yacht-card-banner">New Listing</p> -->
							</div>
							<a href="${ vessel._link }" class="yacht-card-button-container">
								<img loading="lazy" src="${sjys_js.theme_url}/images/right-arrow.svg" alt="yacht-button-link" />
							</a>
						</div>
					</div>
				</div>
			</div>
		`;
	};

	ysp_templates.noResults=function() {

        return `
            <div>
                <b>No Results</b>
            </div>
        `;

    };

	ysp_templates.yacht_tag = function(label, value) {
        return `
            ${value}
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <g clip-path="url(#clip0_205_399)">
            <circle cx="4.5" cy="4.5" r="4.5" fill="transparent"/>
            <path d="M3.09375 5.90625L5.90625 3.09375M3.09375 3.09375L5.90625 5.90625" stroke="#fff" stroke-width="0.69" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_205_399">
            <rect width="9" height="9" fill="white"/>
            </clipPath>
            </defs>
            </svg>
        `;
    };