jQuery(document).ready(function() {
var arrowNextImage = sjys_js.theme_url + "/icons/arrow-right.png";
var arrowPrevImage = sjys_js.theme_url + "/icons/arrow-left.png";
var arrowNextImages = sjys_js.theme_url + "/icons/arrow-right.png";
var arrowPrevImages = sjys_js.theme_url + "/icons/arrow-left.png";


jQuery('.fdb-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerMode: false, 
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2 
            }
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                centerPadding: '20%',
                slidesToScroll: 1 
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1 
            }
        }
    ],
    prevArrow: '<img src="' + arrowPrevImage + '" class="slick-prev" alt="Previous">',
    nextArrow: '<img src="' + arrowNextImage + '" class="slick-next" alt="Next">',
});

  jQuery('.partner-slider').slick({
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1100,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '90px',
            slidesToShow: 4
        }
    },
        {
            breakpoint: 992,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '10px',
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '20%',
                slidesToShow: 1
            }
        }
    ],
    scroll: 1,
});
jQuery('.testimonial-slider').slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        }
    ],
    prevArrow: '<img src="' + arrowPrevImage + '" class="slick-prev" alt="Previous">',
    nextArrow: '<img src="' + arrowNextImage + '" class="slick-next" alt="Next">'
});

jQuery('.hero-slider').slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4100,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
            arrows: false,
            slidesToShow: 1
        }
    },
        {
            breakpoint: 992,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1
            }
        }
    ],
    scroll: 1,
});

jQuery('.firstGallery').slick({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4100,
    arrows: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
            slidesToShow: 1
        }
    },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ],
    prevArrow: '<img src="' + arrowPrevImages + '" class="slick-prev" alt="Previous">',
    nextArrow: '<img src="' + arrowNextImages + '" class="slick-next" alt="Next">',
});

jQuery('.secondaryGallery').slick({
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4100,
    arrows: true,
    swipe: false,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
    ],
    prevArrow: '<img src="' + arrowPrevImages + '" class="slick-prev" alt="Previous">',
    nextArrow: '<img src="' + arrowNextImages + '" class="slick-next" alt="Next">',
});


});