document.addEventListener("DOMContentLoaded", () => {

    var brandDetailsSlider = new Flickity(".brand-carousel", {
      wrapAround: true,
      cellAlign: "center",
      contain: true,
      pageDots: false,
    });
  
    if (document.getElementById("brandGallery")) {
      lightGallery(document.getElementById("brandGallery"), {
        plugins: [lgZoom, lgVideo, lgRotate],
        speed: 500,
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: false,
        download: false,
        selector: ".carousel-cell",
        lazyLoad: true,
      });
    }

    if (document.getElementById("brandSecondGallery")) {
      lightGallery(document.getElementById("brandSecondGallery"), {
        plugins: [lgZoom, lgVideo, lgRotate],
        speed: 500,
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: false,
        download: false,
        selector: ".carousel-cell",
        lazyLoad: true,
      });
    }
  });
  